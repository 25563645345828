const HR = {
  palette: {
    green: "#45d62c",
    dark: "#040f0f",
    olive: "#248232",
    white: "#fcfffc",
    softWhite: "hsl(168, 6%, 83%)",
    grey: "#2d3a3a"
  },
  shadows: {}
};

export default HR;

import React from "react";
import styled from "styled-components";

const Container = ({ children, className }) => (
  <ContainerDiv className={className}>{children}</ContainerDiv>
);

const ContainerDiv = styled.div`
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;

  @media (min-width: 46em) {
    padding-right: 8vw;
    padding-left: 8vw;
  }
`;

export default Container;

import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import styled, { css } from "styled-components";
import { hideVisually } from "polished";
import { ReactComponent as Logo } from "../svg/logo.svg";
import Container from "./container";
import { focusState } from "../tokens/a11y";
import CartloomViewCart from "./cartloomViewCart";

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const LogoLink = styled(Link)`
  margin-left: -1rem;
  padding: 1rem;
  border-radius: 1rem;

  &:focus {
    ${focusState};
  }
`;

const StyledLogo = styled(Logo)`
  display: block;
  width: 100%;
  max-width: 220px;
  height: auto;
`;

const HiddenSiteTitle = styled.span`
  ${hideVisually};
`;

const Menu = styled.ul`
  display: flex;
  align-items: center;
  margin: 0 0 0 1em;
  padding: 0;
  list-style: none;
  font-size: 0.8em;
`;

const navItemVisibility = ({ navVisibility }) =>
  navVisibility === "secondary" &&
  css`
    display: none;

    @media (min-width: 46em) {
      display: list-item;
    }
  `;

const MenuItem = styled.li`
  ${navItemVisibility};
`;

const MenuItemLink = styled(Link)`
  padding: 1.25vw;
  border-radius: 0.5em;

  &:focus {
    ${focusState};
  }
`;

const Header = ({ siteTitle }) => (
  <StaticQuery
    query={graphql`
      query NavigationItemsQuery {
        allMenuItems: allMarkdownRemark(
          filter: { frontmatter: { navVisibility: { nin: ["none", null] } } }
          sort: { fields: [frontmatter___order], order: ASC }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                order
                navVisibility
                navLabel
              }
            }
          }
        }
      }
    `}
    render={data => (
      <header>
        <Container>
          <HeaderContainer>
            <LogoLink to="/">
              <StyledLogo />
              <HiddenSiteTitle>{siteTitle}</HiddenSiteTitle>
            </LogoLink>
            <Menu>
              {data.allMenuItems.edges.map(({ node: menuItem }) => (
                <MenuItem
                  key={menuItem.id}
                  navVisibility={menuItem.frontmatter.navVisibility}
                >
                  <MenuItemLink to={menuItem.fields.slug}>
                    {menuItem.frontmatter.navLabel}
                  </MenuItemLink>
                </MenuItem>
              ))}
              <CartloomViewCart />
            </Menu>
          </HeaderContainer>
        </Container>
      </header>
    )}
  />
);

export default Header;

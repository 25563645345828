import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { ReactComponent as Logo } from "../svg/logo.svg";
import Container from "./container";
import { focusState } from "../tokens/a11y";

const Header = () => (
  <StaticQuery
    query={graphql`
      query FooterItemsQuery {
        footerMarkdownItems: allMarkdownRemark(
          filter: { frontmatter: { footerCategory: { ne: null } } }
          sort: { fields: [frontmatter___order], order: ASC }
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                footerCategory
                navLabel
                footerNavLabel
                order
              }
            }
          }
        }
        legalMarkdownPages: allMarkdownRemark(
          filter: { fields: { slug: { regex: "/legal/" } } }
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { edges: footerItems } = data.footerMarkdownItems;
      const { edges: legalPages } = data.legalMarkdownPages;
      const aboutItems = footerItems.filter(
        ({ node: footerItem }) =>
          footerItem.frontmatter.footerCategory === "about"
      );
      const helpItems = footerItems.filter(
        ({ node: footerItem }) =>
          footerItem.frontmatter.footerCategory === "help"
      );
      const shopItems = footerItems.filter(
        ({ node: footerItem }) =>
          footerItem.frontmatter.footerCategory === "shop"
      );
      const customiseItems = footerItems.filter(
        ({ node: footerItem }) =>
          footerItem.frontmatter.footerCategory === "customise"
      );

      return (
        <FooterOuterWrapper>
          <Container>
            <FooterContainer>
              <StyledLogo />
              <Links>
                {aboutItems && (
                  <LinksBlock>
                    <LinksHeading>About</LinksHeading>
                    <LinksList>
                      {aboutItems.map(({ node: footerItem }) => (
                        <li key={footerItem.fields.slug}>
                          <StyledLink to={footerItem.fields.slug}>
                            {footerItem.frontmatter.footerNavLabel ||
                              footerItem.frontmatter.navLabel}
                          </StyledLink>
                        </li>
                      ))}
                    </LinksList>
                  </LinksBlock>
                )}
                {shopItems && (
                  <LinksBlock>
                    <LinksHeading>Shop</LinksHeading>
                    <LinksList>
                      {shopItems.map(({ node: footerItem }) => (
                        <li key={footerItem.fields.slug}>
                          <StyledLink to={footerItem.fields.slug}>
                            {footerItem.frontmatter.footerNavLabel ||
                              footerItem.frontmatter.navLabel}
                          </StyledLink>
                        </li>
                      ))}
                    </LinksList>
                  </LinksBlock>
                )}
                {customiseItems && (
                  <LinksBlock>
                    <LinksHeading>Customise</LinksHeading>
                    <LinksList>
                      {customiseItems.map(({ node: footerItem }) => (
                        <li key={footerItem.fields.slug}>
                          <StyledLink to={footerItem.fields.slug}>
                            {footerItem.frontmatter.footerNavLabel ||
                              footerItem.frontmatter.navLabel}
                          </StyledLink>
                        </li>
                      ))}
                    </LinksList>
                  </LinksBlock>
                )}
                {helpItems && (
                  <LinksBlock>
                    <LinksHeading>Help</LinksHeading>
                    <LinksList>
                      {helpItems.map(({ node: footerItem }) => (
                        <li key={footerItem.fields.slug}>
                          <StyledLink to={footerItem.fields.slug}>
                            {footerItem.frontmatter.footerNavLabel ||
                              footerItem.frontmatter.navLabel}
                          </StyledLink>
                        </li>
                      ))}
                    </LinksList>
                  </LinksBlock>
                )}
              </Links>
              <LegalFooter>
                <Copyright>
                  &copy; {new Date().getFullYear()} Hydrorace
                </Copyright>
                {legalPages && (
                  <LegalLinks>
                    {legalPages.map(({ node: legalPage }) => (
                      <StyledLegalLink
                        key={legalPage.fields.slug}
                        to={legalPage.fields.slug}
                      >
                        {legalPage.frontmatter.title}
                      </StyledLegalLink>
                    ))}
                  </LegalLinks>
                )}
              </LegalFooter>
            </FooterContainer>
          </Container>
        </FooterOuterWrapper>
      );
    }}
  />
);

const FooterOuterWrapper = styled.footer`
  position: relative;
  background-color: ${({ theme }) => theme.palette.grey};
  color: ${({ theme }) => theme.palette.softWhite};

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 86px;
    top: -85px;
    left: 0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5741 86'%3E%3Cpath fill='%232d3a3a' d='M329 66C251.4 58 97 85 0 85h5741c-482 0-514.5-84-740-84-291 0-370 56.5-694.5 56.5-231.5 0-323-90.5-625.5-25.5s-684.5 53-1022.5 0c-363.6-57-606.5 13-794 13-205 0-513.5 1-652.5-12-98.9-9.2-266 33-382.5 33C710.3 66 659 53 551 66s-125 10-222 0z'/%3E%3C/svg%3E");
    background-repeat: repeat-x;
    background-position: -20% 0;
    background-size: 5741px 86px;

    /* height: 32px;
    top: -31px;
    left: 0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2345d62c' width='1440' height='32'%3E%3Cpath d='M0 32c55.5 0 66.2-13.3 144-28.2C217.7-10.3 215.7 32 366.6 32c111 0 166.2-45.1 241.6-28.2C678.2 19.5 778.8 32 866.3 32c54 0 85-12.1 158.6-20.6 138.7-16 118.2 49.3 260 0C1324-2.2 1402 32 1440 32H0z'/%3E%3C/svg%3E");
    background-repeat: repeat-x;
    background-position: -1000px 0;
    background-size: 3440px 32px; */
    z-index: 2;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 0.8rem;
`;

const StyledLogo = styled(Logo)`
  display: block;
  width: 100%;
  max-width: 160px;
  height: auto;
  margin-top: 1.5rem;
  margin-right: 3rem;
`;

const Links = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-grow: 1;
  margin-top: 0.5rem;
  margin-right: -1rem;
  margin-left: -1rem;
  /* padding-right: 8vw; */
`;

const LinksBlock = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  padding: 1rem 1rem 0;
`;

const LinksHeading = styled.h2`
  margin: 0;
  font-size: 1em;
`;

const LinksList = styled.ul`
  margin: 0.5rem -0.5em 0;
  padding: 0;
  list-style: none;
`;

const StyledLink = styled(Link)`
  display: inline-block;
  padding: 0.125em 0.5em;
  border-radius: 0.5rem;
  color: inherit;

  &:hover,
  &:focus {
    color: currentColor;
  }

  &:focus {
    ${focusState};
  }
`;

const LegalFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 1.5rem;
`;

const Copyright = styled.div`
  margin-right: 2rem;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
`;

const LegalLinks = styled.div`
  display: flex;
  margin-right: -0.75em;
  margin-left: -0.75em;
`;

const StyledLegalLink = styled(StyledLink)`
  padding: 0.5em 0.75em;
`;

export default Header;

import React, { Component } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { hideVisually } from "polished";
// import Cookies from "universal-cookie";
import { ReactComponent as CartIcon } from "../svg/cart.svg";
import { focusState } from "../tokens/a11y";

// const cookies = new Cookies();

const MenuItem = styled.li``;

const MenuItemLink = styled.a`
  display: block;
  padding: 1.25vw;
  border-radius: 0.5em;
  white-space: nowrap;

  &:focus {
    ${focusState};
  }
`;

const HideVisually = styled.span`
  ${hideVisually};
`;

export default class CartloomViewCart extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     data: null
  //   };
  // }

  // componentDidMount() {
  //   const cartloomCookie = cookies.get("hydrorace.cartloom.com_cid");

  //   const proxyUrl = "https://cors-anywhere.herokuapp.com/",
  //     targetUrl = `https://hydrorace.cartloom.com/cl4/cart/counts/${cartloomCookie}?callback=Cartloom_1`;
  //   cartloomCookie &&
  //     fetch(proxyUrl + targetUrl)
  //       .then(blob => blob.json())
  //       .then(json => this.setState({ data: json }))
  //       .catch(e => {
  //         console.log(e);
  //         return e;
  //       });
  // }

  render() {
    return (
      <>
        <Helmet>
          <script
            type="text/javascript"
            id="cljs"
            src="https://hydrorace.cartloom.com/cl4/cart.js"
            async
          />
        </Helmet>
        <MenuItem>
          <MenuItemLink className="cartloom-viewcart" href="#">
            <CartIcon css="display: block; fill: currentColor;" />
            <HideVisually>View cart</HideVisually>
          </MenuItemLink>
        </MenuItem>
      </>
    );
  }
}

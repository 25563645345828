import React from "react";
import styled from "styled-components";
import { focusState } from "../tokens/a11y";
import { hideVisually } from "polished";

const SkipNavigation = ({ skipNavId }) => (
  <Link href={`#${skipNavId}`}>Skip to main content</Link>
);

const Link = styled.a`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 1rem;
  text-align: center;
  background-color: ${({ theme }) => theme.palette.dark};
  z-index: 10;

  :not(:focus):not(:active) {
    ${hideVisually};
  }

  &:focus {
    ${focusState};
  }
`;

export default SkipNavigation;

import React from "react";
import styled from "styled-components";
import Container from "./container";
import Breadcrumb from "./breadcrumb";

const Preamble = ({ breadcrumbs, pageTitle, preambleText }) => {
  if (!breadcrumbs && !pageTitle && !preambleText) return null;
  return (
    <Wrapper>
      <Container>
        {breadcrumbs && <Breadcrumb parts={breadcrumbs} />}
        <Text>
          {pageTitle && <PageTitle>{pageTitle}</PageTitle>}
          {preambleText && <PreambleText>{preambleText}</PreambleText>}
        </Text>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  padding-top: calc(86px + 1rem);
  padding-bottom: calc(86px + 1rem);
  background-color: ${({ theme }) => theme.palette.grey};
  color: ${({ theme }) => theme.palette.softWhite};

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 86px;
    top: -1px;
    left: 0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5741 86'%3E%3Cpath fill='%23040f0f' d='M329 66C251.4 58 97 85 0 85h5741c-482 0-514.5-84-740-84-291 0-370 56.5-694.5 56.5-231.5 0-323-90.5-625.5-25.5s-684.5 53-1022.5 0c-363.6-57-606.5 13-794 13-205 0-513.5 1-652.5-12-98.9-9.2-266 33-382.5 33C710.3 66 659 53 551 66s-125 10-222 0z'/%3E%3C/svg%3E");
    background-repeat: repeat-x;
    background-position: -3000px 0;
    background-size: 5741px 86px;
    z-index: 2;
    transform: rotate(180deg);
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 86px;
    bottom: -1px;
    left: 0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5741 86'%3E%3Cpath fill='%23040f0f' d='M329 66C251.4 58 97 85 0 85h5741c-482 0-514.5-84-740-84-291 0-370 56.5-694.5 56.5-231.5 0-323-90.5-625.5-25.5s-684.5 53-1022.5 0c-363.6-57-606.5 13-794 13-205 0-513.5 1-652.5-12-98.9-9.2-266 33-382.5 33C710.3 66 659 53 551 66s-125 10-222 0z'/%3E%3C/svg%3E");
    background-repeat: repeat-x;
    background-position: 4000px 0;
    background-size: 5741px 86px;
    z-index: 2;
  }
`;

const Text = styled.div`
  max-width: 60ch;
`;

const PageTitle = styled.h1``;

const PreambleText = styled.p`
  font-size: 1.2em;
`;

export default Preamble;

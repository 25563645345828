import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import "typeface-lato";
import HR from "../tokens/colours";
import Header from "./header";
import Footer from "./footer";
import Container from "./container";
import {
  headingLevel1,
  headingLevel2,
  headingLevel3,
  headingLevel4,
} from "../tokens/typography";
import Preamble from "./preamble";
import SkipNavigation from "./skipNavigation";

const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  html {
    box-sizing: border-box;
    font-size: 18px;
    line-height: 1.5;

    @media (min-width: 32em) {
      font-size: 24px;
    }
  }


  body {
    margin: 0;
    font-family: Lato, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    background-color: ${({ theme }) => theme.palette.dark};
    color: ${({ theme }) => theme.palette.softWhite};
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.green};

    &:hover,
    &:focus {
      color: currentColor;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 1em 0 0;
  }

  h1 {
    ${headingLevel1};
  }

  h2 {
    ${headingLevel2};
  }

  h3 {
    ${headingLevel3};
  }

  h4 {
    ${headingLevel4};
  }
`;

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
`;

const HeroWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  min-height: 50vw;
`;

const ImageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;

  & img {
    object-position: 50% 100% !important;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: linear-gradient(
      to bottom,
      ${({ theme }) => theme.palette.dark},
      transparent
    );
    z-index: 1;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MCIgaGVpZ2h0PSI1MCI+CiAgICA8ZyBmaWxsPSJyZ2JhKDAsMCwwLC4yNSkiPgogICAgICAgIDxyZWN0IHdpZHRoPSIyNSIgaGVpZ2h0PSIyNSIgLz4KICAgICAgICA8cmVjdCB4PSIyNSIgeT0iMjUiIHdpZHRoPSIyNSIgaGVpZ2h0PSIyNSIgLz4KICAgIDwvZz4KPC9zdmc+");
    background-size: 4px;
    background-repeat: repeat;
    background-position: 0 0;
    z-index: 1;
  }
`;

const MainContent = styled.div`
  flex-grow: 1;
`;

const Main = styled.main`
  flex-grow: 1;
  padding-top: 1rem;
  padding-bottom: calc(2rem + 86px);
`;

const Layout = ({
  children,
  bannerImage = null,
  breadcrumbs = null,
  pageTitle = null,
  preambleText = null,
  metaDescription = null,
}) => {
  const skipNavId = "mainContent";
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => {
        const headPageTitle = pageTitle
          ? `${pageTitle}: ${data.site.siteMetadata.title}`
          : data.site.siteMetadata.title;
        return (
          <ThemeProvider theme={HR}>
            <>
              <Helmet
                title={headPageTitle}
                meta={[
                  metaDescription
                    ? {
                        name: "description",
                        content: metaDescription,
                      }
                    : {},
                ]}
              >
                <html lang="en" />
              </Helmet>
              <GlobalStyle />
              <LayoutContainer>
                <SkipNavigation skipNavId={skipNavId} />
                {bannerImage ? (
                  <HeroWrapper>
                    <ImageContainer>
                      <Img
                        style={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                        }}
                        fluid={bannerImage}
                      />
                    </ImageContainer>
                    <Header siteTitle={data.site.siteMetadata.title} />
                    <Main id={skipNavId}>
                      <Container>{children}</Container>
                    </Main>
                  </HeroWrapper>
                ) : (
                  <>
                    <Header siteTitle={data.site.siteMetadata.title} />
                    <MainContent id={skipNavId}>
                      <Preamble
                        breadcrumbs={breadcrumbs}
                        pageTitle={pageTitle}
                        preambleText={preambleText}
                      />
                      <Main>
                        <Container>{children}</Container>
                      </Main>
                    </MainContent>
                  </>
                )}
                <Footer />
              </LayoutContainer>
            </>
          </ThemeProvider>
        );
      }}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

import React from "react";
import styled from "styled-components";
import { Link as GatsbyLink } from "gatsby";
import { focusState } from "../tokens/a11y";

const BreadcrumbItem = ({ part, hasSeparator }) => {
  const text = part.href ? (
    <Link to={part.href}>{part.name}</Link>
  ) : (
    <NotLink>{part.name}</NotLink>
  );

  const separator = hasSeparator ? <Separator /> : null;

  return (
    <Item>
      {text}
      {separator}
    </Item>
  );
};

const Breadcrumb = ({ parts, className }) => (
  <BreadcrumbContainer className={className}>
    {parts.map((part, i) => (
      <BreadcrumbItem
        part={part}
        key={part.name + part.href}
        hasSeparator={i < parts.length - 1}
      />
    ))}
  </BreadcrumbContainer>
);

const BreadcrumbContainer = styled.ol`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  margin: 0 -0.5em;
  padding: 0;
  list-style: none;
  font-size: 0.75em;
  line-height: 1;
`;

const Item = styled.li`
  position: relative;

  display: flex;
  align-items: center;
`;

const Link = styled(GatsbyLink)`
  display: inline-block;

  padding: 0.5em;
  border-radius: 0.5rem;
  color: currentColor;

  &:focus {
    ${focusState};
  }
`;

const NotLink = styled.span`
  display: inline-block;
  padding: 0.5em;
`;

const Separator = styled.span`
  &::after {
    content: "/";
    margin: 0 0.25em;
  }
`;

export default Breadcrumb;
